'use client'

import { useEffect, useRef, useState } from 'react'
import type { AsyncStatus } from '~/types/global'

/** Guards `effect`s from invalid re-runs when `StrictMode` is enabled during `development` */
let appInitialized = process.env.NODE_ENV !== 'development'

interface UseLoadingStatusOptions {
	loading: boolean | null | undefined
	error: boolean | null | undefined
	/**
	 * The amount of time (in `milliseconds`) to wait before automatically resetting a status of
	 * `resolved` to `idle`.
	 */
	resetDelay?: number | 'infinite'
}

/**
 * Converts a set of "loading" `boolean` states to a more ergonomic, reliable `string` enum.
 * _Note: This is **not** necessary if the tool you're using already exposes a proper string enum._
 */
function useLoadingStatus({ loading, error, resetDelay = 3000 }: UseLoadingStatusOptions): AsyncStatus {
	const [status, setStatus] = useState<AsyncStatus>('idle')
	const pendingReset = useRef(false)
	const mounted = useRef(false)

	useEffect(() => {
		// Don't run effect during initialization
		if (!appInitialized) {
			appInitialized = true
			return
		}
		if (!mounted.current) {
			mounted.current = true
			return
		}

		// Hook logic
		if (loading) return setStatus('pending') // eslint-disable-line consistent-return -- Nothing is returned here
		if (error) return setStatus('rejected') // eslint-disable-line consistent-return -- Nothing is returned here
		if (pendingReset.current) return

		setStatus('resolved')
		if (resetDelay === 'infinite') return

		pendingReset.current = true
		setTimeout(() => {
			setStatus('idle')
			pendingReset.current = false
		}, resetDelay)
	}, [loading, error, resetDelay])

	return status
}

export default useLoadingStatus
