import type { ProductTypeOptions } from '~/graphql/generated/uacapi/type-document-node'
import { type ExtendedSizes, EXTENDED_SIZES } from '~/lib/products'
import { PreferredSizesGenders, PreferredSizesProductTypes, PreferredSizesSizeTypes } from '~/lib/types/api.interface'
import type { IdmSizePreference } from '~/lib/types/idm.interface'
import type { ClientProductDetail } from '~/lib/types/product.interface'

export const preferredSizesTypeMap = {
	[PreferredSizesGenders.Mens]: {
		[PreferredSizesProductTypes.Tops]: [PreferredSizesSizeTypes.Apparel, PreferredSizesSizeTypes.SizeExtension],
		[PreferredSizesProductTypes.Bottoms]: [
			PreferredSizesSizeTypes.Apparel,
			PreferredSizesSizeTypes.SizeExtension,
			PreferredSizesSizeTypes.Waist,
			PreferredSizesSizeTypes.WaistInseam,
		],
		[PreferredSizesProductTypes.Footwear]: [PreferredSizesSizeTypes.Footwear],
	},
	[PreferredSizesGenders.Womens]: {
		[PreferredSizesProductTypes.Tops]: [PreferredSizesSizeTypes.Apparel, PreferredSizesSizeTypes.SizeExtension],
		[PreferredSizesProductTypes.Bras]: [PreferredSizesSizeTypes.Apparel, PreferredSizesSizeTypes.Bandcup],
		[PreferredSizesProductTypes.Bottoms]: [
			PreferredSizesSizeTypes.Apparel,
			PreferredSizesSizeTypes.SizeExtension,
			PreferredSizesSizeTypes.Waist,
		],
		[PreferredSizesProductTypes.Footwear]: [PreferredSizesSizeTypes.Footwear],
	},
	[PreferredSizesGenders.Boys]: {
		[PreferredSizesProductTypes.Tops]: [PreferredSizesSizeTypes.Youth],
		[PreferredSizesProductTypes.Bottoms]: [PreferredSizesSizeTypes.Youth],
		[PreferredSizesProductTypes.Footwear]: [PreferredSizesSizeTypes.Youth],
	},
	[PreferredSizesGenders.Girls]: {
		[PreferredSizesProductTypes.Tops]: [PreferredSizesSizeTypes.Youth],
		[PreferredSizesProductTypes.Bottoms]: [PreferredSizesSizeTypes.Youth],
		[PreferredSizesProductTypes.Footwear]: [PreferredSizesSizeTypes.Youth],
	},
	[PreferredSizesGenders.Unisex]: {
		[PreferredSizesProductTypes.Footwear]: [PreferredSizesSizeTypes.Footwear],
	},
}

const extendedSizesMinusRegular = Object.keys(EXTENDED_SIZES).filter((s) => s !== 'R')

/**
 * Get product's gender for use with prefered sizing
 */
export const getPreferredSizeGender = (product: ClientProductDetail | undefined) => {
	const gender =
		product?.sizePreferenceType?.gender === PreferredSizesGenders['Adult Unisex']
			? PreferredSizesGenders.Unisex
			: product?.sizePreferenceType?.gender
	return gender && Object.keys(preferredSizesTypeMap).includes(gender) ? gender : undefined
}

/**
 * Get product's product type for use with preferred sizing
 */
export const getPreferredSizeProductType = (product: ClientProductDetail | undefined) => {
	const productType = product?.sizePreferenceType?.productType
	const gender = getPreferredSizeGender(product)
	return !!productType && !!gender && Object.keys(preferredSizesTypeMap[gender]).includes(productType)
		? productType
		: undefined
}

/**
 * Get product's size type for use with preferred sizing
 */
export const getPreferredSizeSizeType = (product: ClientProductDetail | undefined) => {
	const sizeType = product?.variants?.[0].sizePreferenceOption?.sizeType
	const gender = getPreferredSizeGender(product)
	const productType = getPreferredSizeProductType(product)
	return !!sizeType && !!productType && !!gender && preferredSizesTypeMap[gender][productType].includes(sizeType)
		? sizeType
		: undefined
}

/**
 * Does product use extended sizing for preferred sizing
 */
export const isPreferredSizeExtended = (product: ClientProductDetail | undefined) =>
	getPreferredSizeSizeType(product) === PreferredSizesSizeTypes.Apparel &&
	product?.variants?.some(
		(v) =>
			v.sizePreferenceOption?.extendedSize && extendedSizesMinusRegular.includes(v.sizePreferenceOption.extendedSize),
	)

/**
 * Does product have valid data for preferred sizing
 */
export const isPreferredSizeProduct = (product?: ClientProductDetail) => Boolean(getPreferredSizeSizeType(product))

/**
 * Filter list of size preferences from IDM by given gender, product type and/or size type
 */
export const getUserSizePreferencesByType = ({
	gender,
	productType,
	sizeType,
	userSizePreferences,
}: {
	gender?: string
	productType?: string
	sizeType?: string
	userSizePreferences: IdmSizePreference[] | undefined
}): IdmSizePreference[] | undefined => {
	if (!userSizePreferences) return undefined
	const genderMatches = gender ? userSizePreferences.filter((s) => s.gender === gender) : userSizePreferences
	const productTypeMatches = productType ? genderMatches.filter((s) => s.productType === productType) : genderMatches
	return sizeType ? productTypeMatches.filter((s) => s.sizeType === sizeType) : productTypeMatches
}

/**
 * Get a single size value from IDM size preferences that matches
 * the given product's size preference data
 */
export const getUserSizePreferencesByProduct = ({
	product,
	userSizePreferences,
}: {
	product: ClientProductDetail
	userSizePreferences: IdmSizePreference[] | undefined
}): string | undefined => {
	if (!userSizePreferences || !isPreferredSizeProduct(product)) return undefined
	return getUserSizePreferencesByType({
		gender: getPreferredSizeGender(product),
		productType: getPreferredSizeProductType(product),
		sizeType: getPreferredSizeSizeType(product),
		userSizePreferences,
	})?.[0]?.size
}

/**
 * Get the extended size value from IDM size preferences for the given user
 */
export const getPreferredExtendedSize = ({
	product,
	userSizePreferences,
}: {
	product: ClientProductDetail
	userSizePreferences: IdmSizePreference[] | undefined
}): ExtendedSizes | undefined => {
	if (userSizePreferences && isPreferredSizeProduct(product) && isPreferredSizeExtended(product)) {
		const extendedSize = getUserSizePreferencesByType({
			gender: getPreferredSizeGender(product),
			productType: getPreferredSizeProductType(product),
			sizeType: PreferredSizesSizeTypes.SizeExtension,
			userSizePreferences,
		})?.[0]?.size

		if (extendedSize) {
			return extendedSize as ExtendedSizes
		}
	}
	return undefined
}

/**
 * Find and get the extended size value from IDM size preferences that matches
 * the given product's size preference data if it uses extended sizing
 */
export const getProductExtendedSize = ({
	product,
	userSizePreferences,
}: {
	product: ClientProductDetail
	userSizePreferences: IdmSizePreference[] | undefined
}): ExtendedSizes | undefined => {
	if (userSizePreferences && isPreferredSizeProduct(product) && isPreferredSizeExtended(product)) {
		const extendedSize = getPreferredExtendedSize({ product, userSizePreferences })

		if (extendedSize) {
			const productExtendedSizes = product.variants?.reduce((acc, v) => {
				if (v.sizePreferenceOption?.extendedSize && !acc.includes(v.sizePreferenceOption.extendedSize)) {
					acc.push(v.sizePreferenceOption.extendedSize)
				}
				return acc
			}, [] as string[])

			if (productExtendedSizes && productExtendedSizes.includes(extendedSize)) {
				return extendedSize as ExtendedSizes
			}
		}
	}
	return undefined
}

/**
 * Get the preferred size for a given product from supplied size preferences
 * including necessary transformations to extended sizes to match
 * size values found on product variants
 */
export const getPreferredSize = ({
	product,
	userSizePreferences,
}: {
	product: ClientProductDetail
	userSizePreferences: IdmSizePreference[] | undefined
}): string | undefined => {
	if (userSizePreferences && isPreferredSizeProduct(product)) {
		const size = getUserSizePreferencesByProduct({
			product,
			userSizePreferences,
		})

		const extendedSize = getProductExtendedSize({
			product,
			userSizePreferences,
		})
		if (size && extendedSize && extendedSizesMinusRegular.includes(extendedSize)) {
			return size + extendedSize
		}

		return size
	}

	return undefined
}

/**
 * Get the display name (long form name) of the given size and size type
 */
export const getSizeDisplayName = ({
	productTypeOptions,
	sizeType,
	size,
}: {
	productTypeOptions: ProductTypeOptions
	sizeType: string
	size: string
}) =>
	productTypeOptions.sizeTypes.find((s) => s.sizeType === sizeType)?.options.find((o) => o.size === size)
		?.displayName || size

/**
 * Get all product-specific user sizing preferences
 */
export const getPreferredProductSizing = ({
	product,
	userSizePreferences,
}: {
	product: ClientProductDetail
	userSizePreferences: IdmSizePreference[] | undefined
}): {
	preferredSize: string | undefined
	preferredExtendedSize: ExtendedSizes | undefined
	productExtendedSize: ExtendedSizes | undefined
} => ({
	preferredSize: getPreferredSize({ product, userSizePreferences }),
	preferredExtendedSize: getPreferredExtendedSize({ product, userSizePreferences }),
	productExtendedSize: getProductExtendedSize({ product, userSizePreferences }),
})
