import type { ProfileFormData } from '~/components/pages/ProfilePage/ProfilePage'
import isEqual from 'lodash.isequal'
import type { UserWithSession } from '~/lib/client-only/auth/types'
import { AuthGetPreferencesResponseSchema, PreferenceSettingsResponseSchema } from '~/lib/schemas/api'
import { zodParseBodyJson } from './schemas/utils'
import type { AuthGetPreferencesResponse } from './types/api.interface'
import { ensureArray } from '~/types/strict-null-helpers'

/** Transforms an integer representing a month into a formatted "Birth Month Option". `1` represents January. */
export function formatMonth(locale: string, month: number): string {
	const formattedNumber = month.toLocaleString(locale, { minimumIntegerDigits: 2 })
	const formattedMonth = new Date(0, month - 1).toLocaleString(locale, { month: 'long' })
	return `${formattedNumber} - ${formattedMonth}`
}

export const convertExistingModelToFormObject = (profileData) => ({
	...profileData,
	'birthday-month': profileData.birthday?.month ? profileData.birthday?.month.toString() : '', // formData comes through as string
	'birthday-day': profileData.birthday?.day ? profileData.birthday?.day.toString() : '',
	'gender-preferences': profileData.preferences.genders,
	'activity-preferences': profileData.preferences.activities,
})

export const sanitizeProfileData = (formData: ProfileFormData<`${number}` | '', `${number}` | ''>) => ({
	...formData,
	firstName: formData.firstName,
	lastName: formData.lastName,
	phone: (formData.phone ?? '').replaceAll(/[^\d]/g, ''),
	postalCode: formData.postalCode ?? '',
	gender: formData.gender ? formData.gender : null,
	birthday: { month: Number(formData['birthday-month']), day: Number(formData['birthday-day']) },
})

export const isFormDirty = (profileForm: HTMLFormElement, profileData, comparativeKeys) => {
	if (profileForm) {
		const rawFormData = new FormData(profileForm)
		/**
		 * Since some form elements are collections (checkboxes) we need to access them via the getAll method based on
		 * the shared gender or activity preference keys.
		 */
		const formData = {
			...sanitizeProfileData(
				Object.fromEntries(new FormData(profileForm)) as unknown as ProfileFormData<`${number}` | '', `${number}` | ''>,
			),
			'gender-preferences': rawFormData.getAll('gender-preferences'),
			'activity-preferences': rawFormData.getAll('activity-preferences'),
		}
		const originalProfileUserData = convertExistingModelToFormObject(profileData)
		const isDiff = comparativeKeys.some((key) => {
			if (Array.isArray(originalProfileUserData[key])) {
				return !isEqual(originalProfileUserData[key], formData[key])
			}
			return (originalProfileUserData[key] ?? '') !== (formData[key] ?? '')
		})
		if (isDiff || profileForm.querySelector('#new-email') || profileForm.querySelector('#new-password')) {
			return true
		}
	}
	return false
}

/**
 * Request to get the profile preference categories and options
 * We only support one locale for CloudCMS at this time, thus the hard-coded `en_us` in the url pattern.
 */
export async function getPreferenceCategories() {
	try {
		const preferencesResponse = await fetch('https://shop-content.underarmour.com/v2/en_us/preferences/settings/').then(
			(response) => response.json(),
		)
		const preferences = PreferenceSettingsResponseSchema.parse(preferencesResponse)
		return {
			athletes: ensureArray(preferences.categories[0]?.options), // title "Athletes - Onboarding"
			genders: ensureArray(preferences.categories[1]?.options), // title  "Gender - Onboarding"
			activities: ensureArray(preferences.categories[2]?.options), // title "Sports & Activities - Onboarding"
		}
	} catch (e) {
		return {
			athletes: [],
			genders: [],
			activities: [],
		}
	}
}

export async function getPreferences(user?: UserWithSession): Promise<AuthGetPreferencesResponse> {
	const lastUpdated = ''
	const preferences: AuthGetPreferencesResponse['preferences'] = {
		activities: [],
		athletes: [],
		genders: [],
	}
	const sizePreferences: AuthGetPreferencesResponse['sizePreferences'] = []

	if (user?.uacfId && user?.idmAccessToken && user?.uacapiAccessToken) {
		const prefsResponse = await fetch('/api/auth/getpreferences/', {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${user.uacapiAccessToken}`,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				uacfId: user.uacfId,
				idmAccessToken: user.idmAccessToken,
			}),
		})

		const result = await zodParseBodyJson(AuthGetPreferencesResponseSchema, prefsResponse)
		const {
			preferences: { activities, genders },
		} = result

		// apply checked options to the preference input elements
		if (genders.length) {
			const withActivities = activities.length ? genders.concat(activities) : genders
			withActivities
				.filter((prefId) => !!prefId)
				.forEach((id) => {
					const prefCheckEl = document.getElementById(id) as HTMLInputElement
					if (prefCheckEl) prefCheckEl.checked = true
				})
		}

		return result
	}

	return { lastUpdated, preferences, sizePreferences }
}

export function getLoyaltyPointsNextLevel(currentPoints: number) {
	return Math.min(Math.max(Math.ceil(currentPoints / 625), 1), 4) * 625
}

export function getLoyaltyPointsAvailableRewards(currentPoints: number) {
	const level = Math.min(Math.max(Math.floor(currentPoints / 625), 0), 4)
	return level > 2 ? level - 1 : level
}
