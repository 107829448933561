import type z from 'zod'

import type {
	AuthErrorResponseSchema,
	AuthLoginResponseSchema,
	AuthRefreshReponseSchema,
	AuthResetPasswordRequestSchema,
	GiftCardBalanceResponseSchema,
} from '~/lib/schemas/api'

import { AuthGetPreferencesResponseSchema } from '~/lib/schemas/api'

export type AuthErrorResponse = z.infer<typeof AuthErrorResponseSchema>
export type AuthGetPreferencesResponse = z.infer<typeof AuthGetPreferencesResponseSchema>
export type AuthLoginResponse = z.infer<typeof AuthLoginResponseSchema>
export type AuthRefreshResponse = z.infer<typeof AuthRefreshReponseSchema>
export type AuthResetPasswordRequest = z.infer<typeof AuthResetPasswordRequestSchema>
export type GiftCardBalanceResponse = z.infer<typeof GiftCardBalanceResponseSchema>

const SizePreferences = AuthGetPreferencesResponseSchema.shape.sizePreferences.element.shape

export const PreferredSizesGenders = SizePreferences.gender.removeCatch().enum
export const PreferredSizesProductTypes = SizePreferences.productType.removeCatch().enum
export const PreferredSizesSizeTypes = SizePreferences.sizeType.removeCatch().enum

export class ApiError extends Error {
	status?: number

	constructor(status: number, message?: string) {
		super(message)

		this.status = status
	}
}
