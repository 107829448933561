'use client'

import React, { createContext, useCallback, useRef, useState } from 'react'
import { type SizeVariation } from '~/graphql/generated/uacapi/type-document-node'
import { getPublicConfig } from '~/lib/client-server/config'
import type { IdmSizePreference } from '~/lib/types/idm.interface'
import logger from '~/lib/logger'
import { getPreferences } from '~/lib/profile'
import { PreferredSizesSizeTypes } from '~/lib/types/api.interface'
import type { ClientProductDetail } from '~/lib/types/product.interface'
import type { AsyncStatus } from '~/types/global'
import { ensureString, type Optional } from '~/types/strict-null-helpers'
import { useSession } from '~/components/providers/UaSessionProvider/UaSessionProvider'
import useLoadingStatus from '~/components/hooks/useLoadingStatus'
import { useLocale } from '~/components/hooks/useLocale'
import {
	isPreferredSizeProduct,
	isPreferredSizeExtended,
	getPreferredSizeProductType,
	getPreferredSizeGender,
	getPreferredSizeSizeType,
} from '~/lib/preferred-sizes'

type SizeTypePreference = {
	sizeType: string
	size: Optional<string>
}

export type PreferredSizesContextProps = {
	isEnabled: boolean
	getUserSizePreferences: () => IdmSizePreference[] | undefined
	setSizePreferences: (options: {
		gender: string
		productType: string
		preferences: SizeTypePreference[]
	}) => Promise<boolean>
	sizePreferenceUpdateStatus: AsyncStatus
	updateSingleSize: ({
		product,
		selectedSize,
		onlyExtended,
		excludeExtended,
	}: {
		product: ClientProductDetail
		selectedSize: SizeVariation | undefined
		onlyExtended?: boolean
		excludeExtended?: boolean
	}) => Promise<boolean>
}

export const PreferredSizesContext = createContext({
	isEnabled: false,
	getUserSizePreferences: () => ({}),
} as PreferredSizesContextProps)

export const PreferredSizesProvider = ({ children }: React.PropsWithChildren): React.ReactElement => {
	const userSizePreferencesInitialized = useRef(false)
	const [userSizePreferences, setUserSizePreferences] = useState<IdmSizePreference[] | undefined>(undefined)
	const [profileUpdateLoading, setProfileUpdateLoading] = useState(false)
	const [profileUpdateError, setProfileUpdateError] = useState(false)
	const { user } = useSession()
	const locale = useLocale()

	// Is feature enabled in configuration
	const isEnabled = Boolean(user?.isRegistered && getPublicConfig().preferredsizes.locales.includes(locale))

	// Get user preferences from IDM
	const requestPreferenceData = useCallback(async (): Promise<IdmSizePreference[] | undefined> => {
		if (user?.uacfId && user?.idmAccessToken) {
			userSizePreferencesInitialized.current = true
			const { sizePreferences } = await getPreferences(user)
			setUserSizePreferences(sizePreferences)
			return sizePreferences
		}
		return undefined
	}, [user])

	const getUserSizePreferences = useCallback(() => {
		if (!userSizePreferencesInitialized.current) {
			requestPreferenceData()
		}
		return userSizePreferences
	}, [requestPreferenceData, userSizePreferences])

	// Update IDM size preference data
	const setSizePreferences: PreferredSizesContextProps['setSizePreferences'] = useCallback(
		async ({ gender, productType, preferences }) => {
			// Must do hard fetch on size preferences before writing to prevent overwriting data
			const currentUserSizePreferences = await requestPreferenceData()
			if (!currentUserSizePreferences) {
				setProfileUpdateError(true)
				return false
			}
			// merge new data with old data
			const newUserSizePreferences: IdmSizePreference[] = currentUserSizePreferences.filter(
				(s) =>
					!(
						s.gender === gender &&
						s.productType === productType &&
						preferences.map((p) => p.sizeType).includes(s.sizeType)
					),
			)
			// extendedSize property only exists on sizeType=Apparel and sizeType=ExtendedSize
			// and is always null for sizeType=ExtendedSize
			const newLength = preferences.find((s) => s.sizeType === PreferredSizesSizeTypes.SizeExtension)?.size ?? undefined
			preferences.forEach((sizePref) => {
				if (sizePref.size) {
					newUserSizePreferences.push({
						gender,
						productType,
						sizeType: sizePref.sizeType,
						size: sizePref.size ?? undefined,
						...(sizePref.sizeType === PreferredSizesSizeTypes.Apparel ||
						sizePref.sizeType === PreferredSizesSizeTypes.SizeExtension
							? {
									extendedSize: sizePref.sizeType === PreferredSizesSizeTypes.Apparel ? newLength : undefined,
							  }
							: {}),
					})
				}
			})

			setProfileUpdateError(false)
			try {
				setProfileUpdateLoading(true)
				const res = await fetch('/api/auth/v2/updateprofile/', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${user?.uacapiAccessToken}`,
					},
					body: JSON.stringify({
						locale,
						sizePreferences: newUserSizePreferences,
						uacfId: user?.uacfId,
						idmAccessToken: user?.idmAccessToken,
					}),
				})
				setProfileUpdateLoading(false)
				if (res.status === 200) {
					setUserSizePreferences(newUserSizePreferences)
					return true
				}
				setProfileUpdateError(true)
				logger.error(`Problem updating user size preferences.`)
			} catch (e) {
				setProfileUpdateError(true)
				logger.error(`Problem updating user size preferences.`)
			}
			return false
		},
		[locale, user, requestPreferenceData],
	)

	const sizePreferenceUpdateStatus = useLoadingStatus({ loading: profileUpdateLoading, error: profileUpdateError })

	// Update preferences based on product and SizeVariation
	const updateSingleSize: PreferredSizesContextProps['updateSingleSize'] = useCallback(
		async ({ product, selectedSize, onlyExtended = false, excludeExtended = false }) => {
			if (!selectedSize || !isPreferredSizeProduct(product)) return false

			const sizeType = ensureString(getPreferredSizeSizeType(product))
			let newSizePreferences = [{ size: selectedSize.size, sizeType } as SizeTypePreference]

			if (isPreferredSizeExtended(product)) {
				const extendedSizeSplit = selectedSize.size.match(/^(.+[^X]{1,2})(S|T)$/i)
				const baseSize = extendedSizeSplit ? extendedSizeSplit[1] : selectedSize.size
				const extendedSize = extendedSizeSplit ? extendedSizeSplit[2] : 'R'
				if (excludeExtended) {
					newSizePreferences = [
						{
							size: baseSize,
							sizeType,
						},
					]
				} else if (onlyExtended) {
					newSizePreferences = [
						{
							size: extendedSize,
							sizeType: PreferredSizesSizeTypes.SizeExtension,
						},
					]
				} else {
					newSizePreferences = [
						{
							size: baseSize,
							sizeType,
						},
						{
							size: extendedSize,
							sizeType: PreferredSizesSizeTypes.SizeExtension,
						},
					]
				}
			}

			return setSizePreferences({
				gender: ensureString(getPreferredSizeGender(product)),
				productType: ensureString(getPreferredSizeProductType(product)),
				preferences: newSizePreferences,
			})
		},
		[setSizePreferences],
	)

	const contextValue = {
		isEnabled,
		getUserSizePreferences,
		setSizePreferences,
		sizePreferenceUpdateStatus,
		updateSingleSize,
	}

	return <PreferredSizesContext.Provider value={contextValue}>{children}</PreferredSizesContext.Provider>
}
